exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-documentation-index-js": () => import("./../../../src/pages/documentation/index.js" /* webpackChunkName: "component---src-pages-documentation-index-js" */),
  "component---src-pages-editor-index-js": () => import("./../../../src/pages/editor/index.js" /* webpackChunkName: "component---src-pages-editor-index-js" */),
  "component---src-pages-find-index-js": () => import("./../../../src/pages/find/index.js" /* webpackChunkName: "component---src-pages-find-index-js" */),
  "component---src-pages-imprint-index-js": () => import("./../../../src/pages/imprint/index.js" /* webpackChunkName: "component---src-pages-imprint-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parts-index-js": () => import("./../../../src/pages/parts/index.js" /* webpackChunkName: "component---src-pages-parts-index-js" */),
  "component---src-pages-shapes-index-js": () => import("./../../../src/pages/shapes/index.js" /* webpackChunkName: "component---src-pages-shapes-index-js" */),
  "component---src-templates-part-index-js": () => import("./../../../src/templates/part/index.js" /* webpackChunkName: "component---src-templates-part-index-js" */),
  "component---src-templates-shape-index-js": () => import("./../../../src/templates/shape/index.js" /* webpackChunkName: "component---src-templates-shape-index-js" */)
}

